export const getMetaTitleFromData = (data, fallback) => {
  if (typeof window === "undefined") return fallback
  return (
    (Array.isArray(data) ? data : []).find(el => el?.PageUrl === window.location.pathname)
      ?.MetaTitle || fallback
  )
}

export const getTitleFromData = (data, fallback) => {
  if (typeof window === "undefined") return fallback
  return (
    (Array.isArray(data) ? data : []).find(el => el?.PageUrl === window.location.pathname)?.Title ||
    fallback
  )
}
