import backgroundImage from "@images/product-development/backgroundImage.png";
import backgroundImageMobile from "@images/product-development/backgroundImageMobile.png";

export const SECOND_CATCH_UP_SECTION_TITLE = "Get in touch";
export const SECOND_CATCH_UP_SECTION_DESCRIPTION = "Complete contact form, and we will contact you within 24 hours";

export const CASE_STUDIES_INDEXES = [
    { index: 35, order: 1 },
    { index: 32, order: 2 },
];

export const PRODUCT_DEVELOPMENT_PRELOAD_LINKS_MOBILE = [
    {
        rel: "preload",
        href: backgroundImageMobile,
        as: "image",
        fetchPriority: "high"
    }
]

export const PRODUCT_DEVELOPMENT_PRELOAD_LINKS_DESKTOP = [
    {
        rel: "preload",
        href: backgroundImage,
        as: "image",
        fetchPriority: "high"
    }
]
