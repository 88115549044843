import React from "react";
import loadable from "@loadable/component";
import useWindowDimensions from "@hooks/useWindowDimensions";
import useDelayedRender from "@hooks/useDelayedRender";
import backgroundImage from "@images/product-development/backgroundImage.png";
import backgroundImageMobile from "@images/product-development/backgroundImageMobile.png";
import FAQSection from "../Common/FAQSection/FAQSection";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links";
import {
  CASE_STUDIES_INDEXES,
  SECOND_CATCH_UP_SECTION_TITLE,
  SECOND_CATCH_UP_SECTION_DESCRIPTION,
} from "./constants";
import { StyledContactUsButton } from "./styled";
import VideoSection from "../Common/CommonSections/VideoSection/VideoSection";

// const VideoSection = loadable(() =>
//   import("@common/CommonSections/VideoSection/VideoSection")
// );
const CaseStudiesSection = loadable(() =>
  import("@common/CommonSections/CaseStudiesSection/CaseStudiesSection")
);
const CooperationSection = loadable(() =>
  import("./components/CooperationSection/CooperationSection")
);
const WhyChooseSection = loadable(() =>
  import("./components/WhyChooseSection/WhyChooseSection")
);
const StepsSectionWrapper = loadable(() =>
  import("./components/StepsSectionWrapper/StepsSectionWrapper")
);
const CrawlableSecondCatchUpSection = loadable(() =>
  import("@common/CommonSections/CrawlableSecondCatchUpSection")
);

export const productFAQList = [
  {
      title: "What is software product development?",
      iconPath: [null],
      description: (
          <>
              Software product development is the process of designing,
              creating, testing, and deploying software products. It usually
              consists of a series of steps — planning, analysis, design,
              implementation, testing, and maintenance.
              <br />
              Successful software product development requires a
              multidisciplinary team with skills in areas such as software
              engineering, project management, user experience design, and
              quality assurance. The team works together to ensure that the
              software product meets the needs of the users and is of high
              quality, reliability, and usability.
          </>
      ),
  },
  {
      title: "How can you create a software product?",
      iconPath: [null],
      description: (
          <>
              Creating a software product is a complex process that requires a
              team with diverse skills and expertise. Usually this process
              involves the following steps:
              <br />
              - Identify the target audience for the software product, conduct
              market research and gather user feedback to determine what
              features and functionality the software product should have.
              <br />
              - Develop a project plan and define the software requirements.
              <br />
              - Develop a software design that includes the architecture of
              the software, the user interface, and the data model.
              <br />
              - Write the software code and implement the software design. Use
              best practices for coding and testing to ensure that the
              software is high quality and bug-free.
              <br />
              - Test the software thoroughly to ensure that it works as
              intended and meets the software requirements.
              <br />
              - Prepare the software for deployment by packaging the code and
              installing it on servers or devices.
              <br />- Monitor the software after it is deployed to identify
              and fix any bugs or issues that arise. Provide ongoing support
              to users and release updates and new features as needed.
          </>
      ),
  },
  {
      title: "What are the steps of product development?",
      iconPath: [null],
      description: (
          <>
              The steps of software product development can vary depending on
              the specific methodology used and the complexity of the product
              being developed, but a typical process may include the following
              steps:
              <br />
              1. Discovery and planning: Do the market and target audience
              analysis. Identify the requirements and specifications of the
              software product based on customer needs and business
              objectives. Plan the development.
              <br />
              2. Design: Based on the requirements, create a detailed design
              of the software architecture, interface, data models,
              algorithms, and other key features of the product.
              <br />
              3. Implementation: Develop and write code for the product using
              programming languages, frameworks, and tools. This phase also
              involves testing and debugging the code.
              <br />
              4. Testing: Test the software product using different test
              scenarios, test cases, and quality assurance processes to ensure
              that it meets the requirements and specifications.
              <br />
              5. Deployment: Deploy the software product to a live environment
              and configure it for end-users.
              <br />
              6. Maintenance: Provide ongoing maintenance and support for the
              software product by fixing bugs, updating features, and ensuring
              the software remains secure and up-to-date.
              <br />
              It is important to note that these steps can be iterative,
              meaning that the development team may need to revisit earlier
              stages as new information or challenges arise.
          </>
      ),
  },
  {
      title: "How much will it cost to develop a software product?",
      iconPath: [null],
      description: (
          <>
              It is difficult to provide a specific estimate for the cost of
              developing a software product as it can vary widely depending on
              various factors. Among them are:
              <br />
              - The complexity of the software product as more complex
              products require more development effort and resources, which
              can increase the cost.
              <br />
              - Some technologies are more expensive than others, and the cost
              of licensing or purchasing software tools and libraries can also
              add to the cost.
              <br />
              - The size and location of the development team can also affect
              the cost. Larger teams and teams located in areas with higher
              labor costs may result in higher development costs.
              <br />
              - Rushed development timelines can require more resources and
              may result in higher costs.
              <br />A price for software product development services can
              range from tens of thousands of dollars to several million
              dollars. It is important to carefully evaluate the needs of the
              project and work with a team of experienced professionals to
              develop a realistic budget and timeline for the project.
          </>
      ),
  },
  {
      title: "What is included in your product development services?",
      iconPath: [null],
      description: (
          <>
              Our software product development services include a range of
              activities and services that are designed to help businesses or
              individuals create new software products or improve existing
              ones. These services can be customized to meet the specific
              needs of the client, but some of the most common services
              include:
              <br />
              - Discovery and planning: This involves brainstorming, market
              research, and feasibility studies to identify new product ideas
              and refine existing ones.
              <br />
              -Design and prototyping: This involves creating detailed designs
              and producing prototypes or mockups to test and validate the
              product concept.
              <br />
              - Software development: This involves writing software code and
              building the software product based on the design and
              requirements.
              <br />
              - Quality assurance and testing: This involves testing the
              software product to ensure that it meets the required standards
              and specifications.
              <br />
              - Deployment and maintenance: This involves deploying the
              software product and providing ongoing support and maintenance
              to ensure that it remains up-to-date and performs optimally.
              <br />- Project management: This involves managing the project
              from start to finish, including timelines, budgets, and resource
              allocation.
          </>
      ),
  },
  {
      title: "How to choose a reliable software product development company?",
      iconPath: [null],
      description: (
          <>
              Choosing a reliable software product development company
              requires careful consideration and research. Here are some tips
              on how to choose software product development company that would
              be the best fit for your needs:
              <br />
              -Look for a company with a proven success track record by
              checking their portfolio and case studies to see if they have
              experience with your type of project and in the technology stack
              you require.
              <br />
              -Check if the company follows industry standards and best
              practices in software development, such as agile methodologies,
              continuous integration and delivery, and automated testing.
              <br />
              -Choose a company that communicates clearly and takes the time
              to understand your business goals and requirements, and is
              responsive to your questions and concerns.
              <br />
              -Look for a custom software product development company that has
              a clear project management process and methodology in place to
              ensure timely and successful delivery of your project.
              <br />
              -Ask for references and testimonials from previous clients, and
              check reviews on websites such as Clutch or G2 Crowd.
              <br />
              -Compare the pricing and budget of different software product
              development companies, but be cautious of companies that offer
              significantly lower prices than the market standard. Look for
              companies that offer transparent and flexible pricing models.
          </>
      ),
  },
  {
      title: "What is business idea security when outsourcing product development?",
      iconPath: [null],
      description: (
          <>
              Business idea security refers to the protection of confidential
              business information and intellectual property (IP) when
              outsourcing product development to a third-party company.
              Overall, ensuring business idea security when outsourcing
              product development requires careful consideration of the
              outsourcing company&apos;s policies and procedures, as well as
              proper legal and contractual protections.
              <br />
              To ensure business idea security one might consider the
              following steps:
              <br />
              - Sign a non-disclosure agreement (NDA) that is a legal
              agreement that specifies the terms of confidentiality and the
              consequences of breach.
              <br />
              - Ensure that the outsourcing company recognizes and respects
              your intellectual property rights, and that you retain ownership
              of any IP created during the development process.
              <br />
              - Ensure that the outsourcing company has robust data security
              and privacy policies in place.
              <br />
              - Ensure that the outsourcing company has proper access controls
              and permissions in place.
              <br />
              - Ensure that the outsourcing company has robust quality
              assurance and testing processes in place.
              <br />- Specify dispute resolution mechanisms in the outsourcing
              contract, such as mediation or arbitration, to resolve any
              disputes that may arise over the protection of confidential
              information or IP.
          </>
      ),
  },
  {
      title: "Why choose your software development company?",
      iconPath: [null],
      description: (
          <>
              Impressit truly goes above and beyond in providing exceptional
              service and delivering top-quality software solutions to our
              clients. We are proud of our team’s professionalism, expertise,
              and commitment to quality.
              <br />
              We always take time to really understand a client&apos;s needs,
              requirements, and the specific challenges their business might
              face. That is why when working with us, you can expect not only
              responsiveness and attention to details but also proactivity in
              identifying and addressing any potential issues before they
              become major problems.
              <br />
              If you are in need of an excellent custom software product
              development company, look no further than Impressit.
          </>
      ),
  },
];

const ProductDevelopment = ({ caseStudies }) => {
  const { isMobile } = useWindowDimensions();
  const { isDelayed } = useDelayedRender(100);

  return (
    <div className="product-development">
      <VideoSection
        backgroundImage={(isMobile || isMobile === undefined) ? backgroundImageMobile : backgroundImage}
        title="Custom product development"
        description={`
                        Impressit offers full-cycle software development — from research
                        and planning to maintenance of the finished product. Our team's outstanding
                        experience in working with industry-leading technologies and Agile approach
                        are the key to the success of your software product launch.
                    `}
      />
      {isDelayed && (
        <>
          <CooperationSection />
          <StepsSectionWrapper />
          <CaseStudiesSection
            caseStudies={caseStudies}
            indexes={CASE_STUDIES_INDEXES}
            heading="Case studies"
          />
          <WhyChooseSection />
          <FAQSection title="FAQ" FAQList={productFAQList} withPadding />
          <CrawlableSecondCatchUpSection
            isAnimated
            title={SECOND_CATCH_UP_SECTION_TITLE}
            description={SECOND_CATCH_UP_SECTION_DESCRIPTION}
          >
            <StyledContactUsButton
              to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}
            >
              Contact us
            </StyledContactUsButton>
          </CrawlableSecondCatchUpSection>
        </>
      )}
    </div>
  );
};

export default ProductDevelopment;
