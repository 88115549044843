import * as ReactDOMServer from "react-dom/server";

export default class FAQSchemaGenerator {
    static generateFAQ(FAQList) {
        const schema = {
            "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": null
        }

        const mainEntity = []

        for(let faq of FAQList) {
            mainEntity.push({
                    "@type": "Question",
                    "name": faq.title,
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": `<p>${ReactDOMServer.renderToString(faq.description).replace(/<null>|<\/null>/g, "")}</p>`
                    }
            })
        }

        schema["mainEntity"] = mainEntity

        return schema
    }
}